<template>
  <div>
    <portal to="subheader_actions">
      <b-button @click="modalShow = !modalShow" class="mr-2">
        <b-icon icon="plus" aria-label="Help"></b-icon>
        Nuevo
      </b-button>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text>
            <div>
              <b-table
                head-variant="dark"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              ></b-table>
            </div>
          </b-card-text>
          <template v-slot:footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </template>
        </b-card>
      </div>
    </div>

    <b-modal v-model="modalShow" title="Agregar categoría">
      <b-form-group label="Nombre:">
        <b-form-input v-model="form.name" type="text" required></b-form-input>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="saveItem()">
          OK
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      form: {},
      modalShow: false,
      currentPage: 1,
      perPage: 12,
      items: [],
      fields: [
        {
          key: "name",
          sortable: true,
          label: "Nombre"
        }
      ]
    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  methods: {
    listItems() {
      ApiService.get("api/category", "").then((response) => {
        this.items = response.data;
      });
    },
    saveItem() {
      ApiService.post("api/category/", this.form)
        .then(() => {
          this.modalShow = false;
          this.$bvToast.toast("Se ha creado el item correctamente", {
            title: `Éxito`,
            variant: "success",
            solid: true
          });
          this.listItems();
        })
        .catch(() => {
          this.$bvToast.toast("Ha ocurrido un error", {
            title: `Error`,
            variant: "danger",
            solid: true
          });
        });
    }
  },
  created() {
    this.listItems();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mantenimientos" },
      { title: "Categorías" }
    ]);
  }
};
</script>
